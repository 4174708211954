import styles from '../Advertisement.module.scss';
import { getMediaPath } from '~/utils/getPublicPath';
import Link from 'next/link';

const AccessoriesAdvertisement = () => {
  return (
    <Link href=" /categoria/lavagem-e-limpeza">
      <a>
        <picture>
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_288x168.webp')}
            type="image/webp"
            media="all and (max-width:576px)"
          />
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_288x168.png')}
            type="image/png"
            media="all and (max-width:576px)"
          />
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_690x168.webp')}
            type="image/webp"
            media="all and (min-width:577px) and (max-width:1200px)"
          />
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_690x168.png')}
            type="image/png"
            media="all and (min-width:577px) and (max-width:1200px)"
          />
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_2680x236.webp')}
            type="image/webp"
            media="all and (min-width:1201px)"
          />
          <source
            srcSet={getMediaPath('/media/home/bannerparceiro_carroscar_2680x236.png')}
            type="image/png"
            media="all and (min-width:1201px)"
          />
          {/* Default Image */}
          <img
            src={getMediaPath('/media/home/bannerparceiro_carroscar_2680x236.png')}
            className={styles['main__image']}
            alt="ad"
          />
        </picture>
      </a>
    </Link>
  );
};

export { AccessoriesAdvertisement };
