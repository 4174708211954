import styles from './DefaultAdvertisement.module.scss';
import { getMediaPath } from '~/utils/getPublicPath';

const DefaultAdvertisement = () => {
  return (
    <div className={styles['main']}>
      <picture>
        <source
          srcSet={getMediaPath('/media/home/Pecaai-Banner-Mobile-990x636px.png')}
          type="image/png"
          media="all and (max-width:576px)"
        />
        <source
          srcSet={getMediaPath('/media/home/Pecaai-Banner-Tablet-1440x616px.png')}
          type="image/png"
          media="all and (min-width:577px) and (max-width:1200px)"
        />
        <source
          srcSet={getMediaPath('/media/home/Pecaai-Banner-Desktop-1920x616px.png')}
          type="image/png"
          media="all and (min-width:1201px)"
        />
        {/* Default Image */}
        <img
          src={getMediaPath('/media/home/Pecaai-Banner-Desktop-1920x616px.png')}
          className={styles['main__image']}
          alt="ad"
        />
      </picture>
    </div>
  );
};

export { DefaultAdvertisement };
