import styles from './Advertisement.module.scss';
import {
  AcAdvertisement,
  AestheticsWashingAdvertisement,
  WheelsTiresAdvertisement,
  OilChangeAdvertisement,
  AccessoriesAdvertisement,
  DefaultAdvertisement,
} from './categories';

type Props = {
  category?: string;
};

const Advertisement = ({ category = '' }: Props) => {
  return (
    <div className={styles['main']}>
      {(() => {
        switch (category) {
          case 'ar-condicionado':
            return <AcAdvertisement />;
          case 'lavagem-e-limpeza':
            return <AestheticsWashingAdvertisement />;
          case 'rodas-pneus':
            return <WheelsTiresAdvertisement />;
          case 'troca-de-oleo':
            return <OilChangeAdvertisement />;
          case 'acessorios':
            return <AccessoriesAdvertisement />;
          default:
            return <DefaultAdvertisement />;
        }
      })()}
    </div>
  );
};

export { Advertisement };
